@import '../../../styles/variables.less';
@import '../../../styles/responsiveText.less';

.notificationListItemContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 0.75rem;
    padding-left: 0.75rem;
    height: 85px;
    border-radius: 0.5px;

    &:hover {
        background-color: lighten(#afb3ba, 20%);
    }

    .notificationListItemAvatar {
        flex: 1 1 10%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .avatar {
            color: white;
            cursor: default;
            border-radius: 50%;
            margin: 0 auto;
            .responsive-text-small;

            &.avatarStudent {
                background: @c-fuzzy-wuzzy;
            }

            &.avatarInstructor {
                background: @primary-color;
            }

            &.avatarFeature {
                background: @c-purple-light;
            }

            &.avatarAnnouncement {
                background: @warning-color;
            }
        }
    }

    .notificationListItemTextContainer {
        line-height: @line-height;
        flex: 1 1 80%;
        flex-direction: column;
        justify-content: center;

        .notificationListItemTextInfo {
            color: black;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .notificationListItemTextTime {
            color: @text-color-secondary;
            font-size: 14px;
            font-weight: @bold-weight;
        }
    }

    .notificationListItemDot {
        flex: 1 1 10%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        color: @primary-color;

        &.hidden {
            visibility: hidden;
        }
    }
}
