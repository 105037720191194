@import '../../../styles/variables.less';

.footer {
    z-index: 2;
    width: 100%;
    background-color: @c-dark-grey;
    padding: 4rem;
    color: rgb(255, 255, 255, 0.5);
    display: flex;
    gap: 8rem;
}
