@import 'variables';

.responsive-text-large {
    font-size: @large-text-variable-size;

    @media (max-width: @small-screen-max-width) {
        font-size: @large-text-min-size;
    }

    @media (min-width: @large-screen-min-width) {
        font-size: @large-text-max-size;
    }
}

.responsive-text-medium {
    font-size: @medium-text-variable-size;

    @media (max-width: @small-screen-max-width) {
        font-size: @medium-text-min-size;
    }

    @media (min-width: @large-screen-min-width) {
        font-size: @medium-text-max-size;
    }
}

.responsive-text-medium-small {
    font-size: @medium-small-text-variable-size;

    @media (max-width: @small-screen-max-width) {
        font-size: @medium-small-text-min-size;
    }

    @media (min-width: @large-screen-min-width) {
        font-size: @medium-small-text-max-size;
    }
}

.responsive-text-small {
    font-size: @small-text-variable-size;

    @media (max-width: @small-screen-max-width) {
        font-size: @small-text-min-size;
    }

    @media (min-width: @large-screen-min-width) {
        font-size: @small-text-max-size;
    }
}

.responsive-text-extra-small {
    font-size: @extra-small-text-variable-size;

    @media (max-width: @small-screen-max-width) {
        font-size: @extra-small-text-min-size;
    }

    @media (min-width: @large-screen-min-width) {
        font-size: @extra-small-text-max-size;
    }
}
