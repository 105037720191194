@import '../../styles/variables.less';

.headerIcon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 2rem;
    padding: 0 1rem;
    margin: 0 1rem;
}

.siteTitleContainer {
    width: 200px;
    .siteTitle {
        font-family: @f-header;
        padding: 0;
        color: @primary-color;
        font-size: 125%;
        letter-spacing: 0.3rem;
        display: flex;

        .logoContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &:hover {
            //color: @highlight-color;
            opacity: 0.75;
            cursor: pointer;
        }

        &.siteTitleContrasted {
            color: whitesmoke;
            opacity: 0.9;
            &:hover {
                opacity: 1;
            }
        }
    }
    .siteLogo {
        color: @c-fuzzy-wuzzy;
    }
}
