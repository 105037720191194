@import '../../styles/variables';

.chooseFrontendDisplayedCourse {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chooseFrontendDisplayedCourseTitle {
        font-weight: @bold-weight;
        color: @heading-color;
        margin: 0 auto;
    }
}
