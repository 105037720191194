.searchResultsDropdownContainer {
    top: 232px !important; // does this work on differently sized screens?
    max-height: calc(100vh - 232px - 1px);

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.searchResultsDropdownContainer::-webkit-scrollbar {
    display: none;
}

.headerIcon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 2rem;
    padding: 0 1rem;
    margin: 0 1rem;
}
