@import '../../../styles/variables';

.frameContainer {
    font-size: @font-size-base;
    min-height: 100vh;
    max-width: 100vw;
    min-width: 360px;

    display: flex;
    flex-direction: column;

    .frameHeader {
    }

    .frameBody {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
