// Include global styles here

@import 'variables';
@import 'responsiveText';

body.block-body-scroll {
    overflow: hidden;
}

.container {
    margin: 0 auto;
    height: 100%;
    width: 100%;

    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .column {
        flex-basis: 100%;
    }

    @media screen and (min-width: 800px) {
        .column {
            flex: 1;
        }
    }
}

.react-swipeable-view-container {
    max-width: 100vw;
    height: 100%;

    .swipeable-views-slider-container {
        // give this a better name
        //height: 100%; // for some reason not setting height 100% actually allows it to be height 100%...this library is doing weird things
        overflow: visible !important; // prevents slider from overriding with overflow: auto and showing inner scroll bars
    }
}

button.has-unsaved-changes {
    background: @unsaved-changes-color;

    &:hover,
    &:focus {
        background: @unsaved-changes-color;
    }
}

.ant-select.has-unsaved-changes {
    .ant-select-selector {
        background: @unsaved-changes-color;
    }
}

.clickable-text {
    color: @primary-color;
    cursor: pointer;

    &:hover {
        color: @highlight-color;
    }

    &.underline {
        text-decoration: underline;
        // experimental, doesn't seem to work very well
        text-underline-position: under;
    }

    // set a visited style?
}

.invisible {
    visibility: hidden;
}

.content-section {
    padding: 1.25rem 1.25rem 0.3125rem 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @f-text;
}

p,
div {
    font-family: @f-text;
}

p {
    margin: 0;
}

// TODO: Now that I understand css a bit more, may be able to lighten up on all the !importants everywhere
.ant-btn {
    border-radius: 5px;

    &.ant-btn-primary {
        &:hover,
        &:focus {
            &.ant-btn-primary[disabled] {
                background-color: @disabled-background !important;
            }
        }

        &:active {
            // when clicked
            -webkit-box-shadow: @btn-active-shadow !important;
            -moz-box-shadow: @btn-active-shadow !important;
            box-shadow: @btn-active-shadow !important;
            outline: none !important;
            background-color: darken(@primary-color, 2.5%) !important;
        }

        // May want to revisit these colors, but will do for now
        &.darkened {
            background-color: darken(@primary-color, 25%);

            &:hover,
            &:focus {
                background-color: lighten(darken(@primary-color, 25%), 10%);
            }
        }
    }

    &.ant-btn-secondary {
        background-color: @c-white-background !important;
        color: @c-blue-light !important;

        &.ant-btn[disabled] {
            background-color: @disabled-background !important;
            color: @disabled-color !important;
        }

        &:hover,
        &:focus {
            color: @highlight-color !important;
            border-color: @highlight-color !important;

            &.ant-btn[disabled] {
                color: @disabled-color !important;
                border-color: @disabled-border-color !important;
            }
        }

        &:active {
            // when clicked
            -webkit-box-shadow: @btn-active-shadow !important;
            -moz-box-shadow: @btn-active-shadow !important;
            box-shadow: @btn-active-shadow !important;
            outline: none !important;
            color: @highlight-color !important;
            border-color: @highlight-color !important;
        }
    }
}

// Tabs
.ant-tabs-tab {
    &:not(.ant-tabs-tab-disabled) {
        color: @primary-color !important;

        &:hover {
            color: @highlight-color !important;
        }
    }

    &.ant-tabs-tab-active {
    }
}

// Tab underline
//.ant-tabs-ink-bar {
//    background-color: @primary-color !important;
//}

// Modal (login / logoutPage)
//.ant-modal-body {
//
//    .ant-modal-x {
//    }
//}

//.ant-input, .ant-input-password {
//    border-color: @c-blue-light !important;
//}
//
//.ant-input:hover, .ant-input-password:hover {
//    border-color: @highlight-color !important;
//}
//
.ant-input:focus,
.ant-input-password:focus {
    //box-shadow: 0 0 0 0.125rem fade(@highlight-color, 50%) !important;
    border-color: @highlight-color !important;
}

.click-me {
    cursor: pointer;
}

.no-highlight {
    user-select: none;
}

.hover-me {
    &:hover {
        color: @highlight-color !important;
    }
}

.ant-drawer-body {
    z-index: 20;
}

.anticon-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.blur-me {
    filter: blur(0.25rem);
    -webkit-filter: blur(0.25rem);
}

.dont-touch-me {
    pointer-events: none;
    user-select: none;
}

.secondary-text {
    color: @c-default-font-secondary;
}

.ant-dropdown {
    background-color: whitesmoke;
}

.bold-me {
    font-weight: @bold-weight;
}

.center-children-vertically {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ellipsis-me {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// content tree node
.content-tree-root {
    padding: 1rem;

    .ant-collapse {
        //background-color: inherit;
        //border: none;

        .ant-collapse-item {
            //border: none;

            &.ant-collapse-item-active {
                border: solid 2px black;
            }

            .ant-collapse-content {
                //background-color: inherit;
                //border: none;
                .ant-collapse-content-box {
                    padding: 16px 32px;

                    .collapse-footer {
                        margin-bottom: 1rem;
                        display: flex;
                        column-gap: 1rem;
                    }
                }
            }
        }
    }
}

// content node reorderer
.reorder-node-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 150%;
    row-gap: 0.5rem;

    .anticon {
        color: @primary-color;

        &:hover {
            color: @highlight-color;
        }

        &.disabled {
            color: @disabled-color;
            cursor: not-allowed;
        }
    }
}

// defensive delete button
.defensive-delete-button {
    display: inline;

    .defensive-delete-button-delete-icon-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .anticon {
            cursor: pointer;
            font-size: 150%;
            color: @error-color;

            &:hover {
                color: lighten(@error-color, 15%);
            }
        }
    }

    &.disabled {
        // required because of this bug: https://lightrun.com/answers/react-component-tooltip-mouseleave-dont-trigger-on-disabled-inputs-and-button
        button {
            pointer-events: none;
        }

        .defensive-delete-button-delete-icon-container {
            .anticon {
                cursor: not-allowed;
                color: @disabled-color;
            }
        }
    }
}

.defensive-delete-button-modal {
    .ant-modal-content {
        .ant-modal-header {
            .ant-modal-title {
                display: flex;
                column-gap: 2rem;
                justify-content: center;

                .defensive-delete-button-modal-title-text-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .warning-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .anticon {
                        font-size: 200%;
                        color: @error-color;
                    }
                }
            }
        }
    }
}

// select content path
.select-content-path {
    .select-content-path-item {
        .select-content-path-item-title {
            // TODO:
        }

        .ant-select {
            width: 100%;
            .ant-select-selection-item {
                overflow: hidden !important;
            }
        }
    }
}

// search box
.search-box-modal {
    margin: 0 3rem;
    width: calc(100% - 6rem) !important;
    @media (max-width: calc(@small-screen-max-width + 50px)) {
        margin: 0 1rem;
        width: calc(100% - 2rem) !important;
    }

    .ant-modal-content {
        width: 100%;
        margin: 0;

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-content: center;
            height: 100%;

            .search-box-title-text-container {
                height: 61.25px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .search-box-title-text {
                    color: @primary-color;
                    font-weight: @bold-weight;
                    .responsive-text-medium-small;
                    padding-bottom: 1rem;
                }
            }

            .search-box-with-button {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .ant-select-auto-complete {
                    flex: 95%;
                }

                .search-button-in-modal {
                    flex: 5%;
                    font-size: 2rem;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    color: @primary-color;

                    &:hover {
                        color: @highlight-color;
                    }
                }
            }
        }
    }
}

// top navigation
@header-base-color: @primary-color;
@header-hover-color: @highlight-color;
@navbar-height: 6rem;

.top-navigation {
    display: flex;
    justify-content: space-between;
    height: @navbar-height;

    .anticon.anticon-search,
    .anticon.anticon-bell {
        font-size: 2rem;
        cursor: pointer;
        color: @primary-color;

        &:hover {
            box-shadow: @icon-shadow;
            color: @highlight-color;
        }
    }

    .logout-button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2rem;
        font-weight: @bold-weight;
        color: @primary-color;

        &:hover {
            color: @highlight-color;
        }
    }
}

// search results
.search-results {
    .ant-menu-submenu-title {
        font-weight: @bold-weight;
    }
}

.ant-menu-submenu-open .ant-menu-submenu-title {
    color: @white;
    background-color: @c-blue-light !important;

    &:hover {
        color: darken(@white, 5%) !important;
    }
}

.ant-menu-item,
.ant-menu-submenu-title {
    color: @header-base-color;

    &:not(.ant-menu-item-disabled) {
        &:hover {
            color: @header-hover-color !important;
        }
    }

    &:active {
        color: @white;
        background-color: @c-blue-light !important;
    }

    // when hovered, down arrow
    .ant-dropdown-trigger:hover .anticon.anticon-down {
        box-shadow: @icon-shadow;
    }

    &.furthest-right {
        margin-right: 0;
    }

    // when active, menu items
    &.ant-menu-item-active {
        color: @header-hover-color !important;

        // when menu is active, keep it popped out
        .anticon.anticon-down {
            box-shadow: @icon-shadow;
        }
    }

    &.ant-menu-item-selected {
        color: @white !important;
        background-color: @c-blue-light !important;

        &:hover {
            color: darken(@white, 5%) !important;
        }
    }
}

// notification modal
.notification-modal {
    .ant-modal-content {
        border-radius: 10px;
        overflow-y: auto;
        max-height: 500px;
    }
    .notification-modal-title {
        .responsive-text-medium-small;
        padding-bottom: 0.5rem;
    }

    .ant-list-item {
        border-block-end: 1px solid @c-gray-300 !important;

        &:hover {
            background-color: @primary-color-lighter;
        }

        .ant-list-item-meta {
            .ant-list-item-meta-avatar {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 50px;
            }
        }
    }
}

// loading bar
.loading-bar-container {
    /* Make clicks pass-through */

    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: @primary-color;

        position: absolute;
        z-index: 10;

        width: 100%;
        height: 0.125rem;
    }

    &.header-not-at-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
}

// magic text box
.magic-text-box-editor {
    width: 100%;

    // See 'useOutlineDistanceFromEdgeProperty
    .magic-text-box {
        .ant-float-btn-group {
            // TODO: Hack for now, but makes scrolling funky
            //  Issue is the modal that these are rendered within
            position: fixed !important;

            //inset-inline-end: var(--outline-distance-from-edge);

            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;

            // buttons
            div {
                button {
                    overflow: visible;

                    .ant-float-btn-body {
                        overflow: inherit;

                        .ant-float-btn-content {
                            overflow: inherit;

                            .ant-float-btn-icon {
                                .ant-badge {
                                    .ant-scroll-number {
                                        background: @primary-color;
                                    }
                                }
                            }
                        }
                    }

                    .anticon {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}

// practice problem link (maybe antd? not found in the codebase here?)
.practice-problem-link-modal {
    width: 100% !important;
    .ant-modal-content {
        width: 100%;
        margin: 0;

        .ant-modal-body {
        }
    }
}
